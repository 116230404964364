<template>
  <b-navbar
    class="mt-6 footer-color"
    toggleable="md"
  >
    <b-navbar-nav class="mr-auto">
      <b-nav-item
        class="h9 mx-2 small-btn mt-1"
        @click="prev"
      >
        Previous step
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav class="right-navbar">
      <b-btn
        v-if="value < maxSteps"
        variant="ghost"
        :disabled="buttonsDisabled"
        class="next-btn mx-2"
        title="Fill out all required fields in this step."
        @click="next"
      >
        Next Step
      </b-btn>
      <b-btn
        :disabled="submitDisabeld"
        variant="primary"
        class="submit-btn mx-2"
        title="In order to submit your profile please fill all the required fields."
        @click="$emit('submit')"
      >
        Submit profile
      </b-btn>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
export default {
  name: 'FormFooter',
  props: {
    value: {
      type: Number,
      required: true,
    },
    maxSteps: {
      type: Number,
      required: true,
    },
  },
  computed: {
    submitDisabeld () {
      if (this.buttonsDisabled) {
        return true
      }
      return this.value < this.maxSteps
    },
    buttonsDisabled () {
      let stepRequriedFields = []
      stepRequriedFields = {
        0: [
          'linkedin',
          'country',
          'employees',
          'roles',
          'services',
          'industries',
          'regions',
          'local_insights',
        ],
        1: [
          'availability',
          'pricing_models',
          'fee_ranges',
          'languages',
          'english_level',
        ],
        2: [
          'title',
          'description',
        ],
      }

      let disabled = true
      for (let i = 0; i < stepRequriedFields[this.value].length; i++) {
        const field = stepRequriedFields[this.value][i]
        // Check if array fields are empty
        // if they are empty, then set disabled to true and return
        if (Array.isArray(this.$store.state.agency.signupForm[field])) {
          if (this.$store.state.agency.signupForm[field].length === 0) {
            disabled = true
            break
          }
          disabled = false
        } else {
          // Check if non-array fields are filled
          if (!this.$store.state.agency.signupForm[field]) {
            disabled = true
            break
          }
          disabled = false
        }
      }
      return disabled
    },
  },
  methods: {
    prev () {
      if (this.value > 0) {
        const temp = this.value - 1
        this.$emit('input', temp)
        window.scrollTo(0, 0)
      } else if (this.value === 0) {
        this.$router.push('/signup/agency/goals?token=' + this.$route.query.token)
      }
    },
    next () {
      if (this.value < this.maxSteps) {
        this.$store.dispatch('agency/updateSignup', this.$route.query.token)
        const temp = this.value + 1
        this.$emit('input', temp)
        window.scrollTo(0, 0)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.footer-color {
  background-color: #FFF2EF;
  .next-btn {
    background-color: #FFF2EF;
    min-width: 10%;
    border-radius: 4px;
    font-size: 14px;
    color: #FB9A74;
    border-color: #FB9A74;

    &:hover {
      background-color: #FFF2EF !important;
      color: #FB9A74;
    }
  }
  .submit-btn {
    min-width: 10%;
    border-radius: 4px;
    font-size: 14px;
    color: #FFFFFF;
    background-color: #FB9A74;
  }
  .small-btn {
    border: none;
    background: none;
    color: #474546;
    font-weight: 400;
    padding: 6px;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 14px;
  }
  .navbar-nav.right-navbar {
    flex-direction: row;
  }
  .second-headline {
    font-size: 18px;
    line-height: 140%;
  }
}
</style>
