<template>
  <b-container
    fluid
    class="p-0"
    style="background: #F8F6F7;"
  >
    <FormHeader />
    <b-container class="main-container d-flex justify-content-center mt-5">
      <b-form @submit="onSubmit">
        <b-row>
          <b-col class="ml-2 mr-2 mb-3">
            <ProgressBar2
              v-model="activeStep"
              :max-steps="maxSteps"
            />
          </b-col>
        </b-row>
        <keep-alive>
          <DetailsStep1 v-if="activeStep === 0" />
          <DetailsStep2 v-if="activeStep === 1" />
          <DetailsStep3 v-if="activeStep === 2" />
        </keep-alive>
      </b-form>
    </b-container>
    <SignupFooter
      v-model="activeStep"
      :max-steps="maxSteps"
      @submit="onSubmit"
    />
  </b-container>
</template>

<script>
import FormHeader from '@/components/form/FormHeader'
import ProgressBar2 from '@/components/form/ProgressBar2'
import DetailsStep1 from '@/modules/agency/components/DetailsStep1'
import DetailsStep2 from '@/modules/agency/components/DetailsStep2'
import DetailsStep3 from '@/modules/agency/components/DetailsStep3'
import SignupFooter from '@/modules/agency/components/SignupFooter'

export default {
  name: 'SignupForm',
  components: {
    FormHeader,
    ProgressBar2,
    DetailsStep1,
    DetailsStep2,
    DetailsStep3,
    SignupFooter,
  },
  data () {
    return {
      activeStep: 0,
      maxSteps: 2,
      submitting: false,
    }
  },
  methods: {
    onSubmit () {
      if (!this.submitting) {
        this.submitting = true

        this.$store.dispatch('agency/updateSignup', this.$route.query.token)
          .then(() => {
            this.$router.push('/login')
          })
          .finally(() => {
            this.submitting = false
          })
      }
    },
  },

}
</script>

<style lang="scss" scoped>
.main-container {
  max-width: 800px;
}
</style>
