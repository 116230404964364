<template>
  <b-container
    fluid
    class="p-0"
    style="background: #faf4f2;"
  >
    <b-container class="main-container d-flex justify-content-center mt-5">
      <b-form @submit="onSubmit">
        <b-row class="mt-3 mb-5">
          <b-col class="text-center h7">
            Describe your recruitment experience
          </b-col>
        </b-row>
        <b-row class="main-row pl-3 pr-3">
          <b-col class="mt-3 mb-4">
            <b-col
              class="mb-4"
              cols="12"
              xl="7"
            >
              <label class="h8">
                Availability
                <span class="text-primary">*</span>
              </label>
              <p class="h9">
                Are you currently available to take on new recruiting projects?
              </p>
              <b-form-group>
                <b-form-radio-group
                  v-model="availability"
                  class="test"
                >
                  <b-form-radio value="available">
                    <p class="pt-1 h9">
                      Available
                    </p>
                  </b-form-radio>
                  <b-form-radio value="not_available">
                    <p class="pt-1 h9">
                      Not available
                    </p>
                  </b-form-radio>
                  <b-form-radio value="available_from">
                    <p class="pt-1 h9">
                      Available from
                    </p>
                  </b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-datepicker
                v-if="availability === 'available_from'"
                v-model="available_from"
                class="datepicker ml-4"
                name="datepicker"
                :disabled="disabled"
                size="sm"
                placeholder="Choose Date"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: undefined }"
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
              xl="8"
            >
              <label class="h8">
                Previous clients/employers
              </label>
              <p class="h9">
                Name only some of the trophy client <b>names</b>:
              </p>
              <FormTextArea
                v-model="previous_clients"
                name="previous_clients"
                placeholder="e.g Relancer, Nike, Facebook"
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
            >
              <label class="h8">
                Your preferred compensation model
                <span class="text-primary">*</span>
              </label>
              <p class="h9">
                Select as many as suitable
              </p>
              <BadgeCheckbox
                v-model="pricing_models"
                name="pricing_models"
                :options="feeOptions"
                required
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
            >
              <label class="h8">
                Pricing
                <span class="text-primary">*</span>
              </label>
              <p class="h9">
                Select as many as suitable
              </p>
              <BadgeCheckbox
                v-model="fee_ranges"
                name="fee_ranges"
                :options="feeRangeOptions"
                required
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
            >
              <label class="h8">
                Working language(s)
                <span class="text-primary">*</span>
              </label>
              <FormTags
                v-model="languages"
                name="languages"
                :options="languageOptions"
                button-text="Working language(s)"
                class="borderless-tags"
                required
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
            >
              <label class="h8">
                Fluency in English
                <span class="text-primary">*</span>
              </label>
              <BadgeCheckbox
                v-model="english_level"
                name="english_level"
                :options="englishFluencyOptions"
                required
                :limit="1"
              />
            </b-col>
          </b-col>
        </b-row>
      </b-form>
    </b-container>
  </b-container>
</template>

<script>
import axios from '@/helpers/axios'
import BadgeCheckbox from '@/components/form/BadgeCheckbox'
import FormTextArea from '@/components/form/TextArea'
import { vuexMapper } from '@/helpers/vuexMapper'
import FormTags from '@/components/FormTags'

const SENIORITY_OPTIONS_ORDER = {
  Entry: 1,
  Junior: 2,
  'Mid-level': 3,
  Senior: 4,
  Expert: 5,
}

const PRICING_MODEL_OPTIONS = {
  'Hourly fee': 1,
  'Fixed fee with pre-payment': 2,
  'Contingency (% of the annual salary)': 3,
  Other: 4,
}

const PRICING_RANGE_OPTIONS = {
  '< €30/h': 1,
  '€30/h - €60/h': 2,
  '€60/h - €90/h': 3,
  '> €90/h': 4,
  '< €3000 per hire': 5,
  '€3000 - €6000 per hire': 6,
  '€6000 - €9000 per hire': 7,
  '> €9000 per hire': 8,
  '< 12% of annual salary': 9,
  '12% - 20% of annual salary': 10,
  '> 20% of annual salary': 11,
}

export default {
  name: 'AgencySignupDetailsStep2',
  components: {
    FormTextArea,
    BadgeCheckbox,
    FormTags,
  },
  data () {
    return {
      seniorityOptions: [],
      feeOptions: [],
      englishFluencyOptions: [
        { text: 'Basic', value: 'Basic' },
        { text: 'Conversational', value: 'Conversational' },
        { text: 'Fluent', value: 'Fluent' },
        { text: 'Native or bilingual', value: 'Native or bilingual' },
      ],
      languageOptions: [],
      feeRangeOptions: [],
    }
  },
  computed: {
    disabled () {
      return this.availability !== 'available_from'
    },
    ...vuexMapper({
      fields: [
        'availability',
        'available_from',
        'previous_clients',
        'seniority',
        'pricing_models',
        'fee_ranges',
        'english_level',
        'languages',
      ],
      base: 'agency',
      nestedObject: 'signupForm',
      mutation: 'UPDATE_STORE',
    }),
  },
  mounted () {
    axios.get('/public/v2/values/seniorities')
      .then(({ data }) => {
        let temp = this.mapValues(data.filter(s => s.label !== 'Not important'), true)
        temp = temp.map(option => {
          option.order = SENIORITY_OPTIONS_ORDER[option.label]
          return option
        })
        temp = temp.sort((a, b) => (a.order > b.order) ? 1 : -1)
        this.seniorityOptions = temp
      })
    axios.get('/public/v2/values/pricing_models')
      .then(({ data }) => {
        let temp = this.mapValues(data)
        temp = temp.map(option => {
          option.order = PRICING_MODEL_OPTIONS[option.label]
          return option
        })
        temp = temp.sort((a, b) => (a.order > b.order) ? 1 : -1)
        this.feeOptions = temp
      })
    axios.get('/public/v2/values/fee_ranges')
      .then(({ data }) => {
        let temp = this.mapValues(data, false, 'label')
        temp = temp.map(option => {
          option.order = PRICING_RANGE_OPTIONS[option.label]
          return option
        })
        temp = temp.sort((a, b) => (a.order > b.order) ? 1 : -1)
        this.feeRangeOptions = temp
      })
    axios.get('/public/v2/values/languages')
      .then(({ data }) => {
        let temp = this.mapValues(data)
        temp = temp.sort((a, b) => (a.label > b.label) ? 1 : -1)
        this.languageOptions = temp
      })
  },
  methods: {
    mapValues (data) {
      const dataArr = data.map(option => {
        option.value = option.id
        option.text = option.label
        return option
      })
      return dataArr
    },
    onSubmit (event) {
      event.preventDefault()
    },
  },

}
</script>

<style lang="scss" scoped>
.main-container {
  .borderless-tags {
    border: none;
    box-shadow: none;
    padding: 0;
  }
  .main-row {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(216, 216, 216, 0.5);
    border-radius: 12px;
  .form-control::-webkit-input-placeholder {
    font-weight: 300;
    font-size: 14px;
    font-style: italic;
    line-height: 120%;
    color: #979797;
    font-family: Jost;
    }
    .custom-control-inline {
      display: flex;
    }
    .form-group {
      margin-bottom: 4px;
    }
  }
}
</style>
