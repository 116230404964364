<template>
  <b-container
    fluid
    class="p-0"
  >
    <b-container class="main-container d-flex justify-content-center mt-5">
      <b-form @submit="onSubmit">
        <b-row class="mt-3 mb-5">
          <b-col class="text-center h7">
            Describe your recruitment experience
          </b-col>
        </b-row>
        <b-row class="main-row pl-3 pr-3">
          <b-col class="mt-3 mb-4">
            <b-col
              class="mb-4"
              cols="12"
              xl="7"
            >
              <label class="h8">
                Agency one-liner
                <span class="text-primary">*</span>
              </label>
              <FormTextArea
                v-model="title"
                name="title"
                required
                :rows="1"
                placeholder="e.g. Recruiting agency with 7 years experince in recruiting senior developers"
              />
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
              xl="7"
            >
              <label class="h8">Agency logo</label>
              <p class="h9">
                If you don't want to add your logo, you'll get a cute illustration instead
              </p>
              <b-form-group>
                <b-img
                  v-if="agency_logo"
                  :src="agency_logo"
                  class="agency_logo mb-2"
                  rounded="circle"
                />
                <label
                  for="agency_logo"
                  class="agency_logo_upload"
                  :style="{ backgroundImage: 'url(' + agency_logo + ')' }"
                />
                <!-- v-model="agency_logo" -->
                <b-form-file
                  id="agency_logo"
                  class="agency_logo"
                  :state="Boolean(agency_logo)"
                  accept=".jpg, .jpeg, .png, .gif"
                  name="agency_logo"
                  size="sm"
                  placeholder="Add a logo"
                  drop-placeholder="Drop file here..."
                  @input="uploadPic"
                />
              </b-form-group>
            </b-col>
            <b-col
              class="mb-4"
              cols="12"
              xl="7"
            >
              <label class="h8">
                Description
                <span class="text-primary">*</span>
              </label>
              <p class="h9">
                What makes you special? Describe your superskills and distinctive experiences with 3-5 sentences. Good luck! 💪
              </p>
              <FormTextArea
                v-model="description"
                name="description"
                required
                :rows="3"
                placeholder="e.g We're the best recruiter the world has ever seen. We can fill all non-tech roles in 2 weeks and all tech roles in 1 week."
              />
            </b-col>
          </b-col>
        </b-row>
      </b-form>
    </b-container>
  </b-container>
</template>

<script>
import axios from '@/helpers/axios'
import FormTextArea from '@/components/form/TextArea'
import { vuexMapper } from '@/helpers/vuexMapper'

export default {
  name: 'AgencySignupDetailsStep3',
  components: {
    FormTextArea,
  },
  computed: {
    ...vuexMapper({
      fields: [
        'title',
        'description',
        'agency_logo',
      ],
      base: 'agency',
      nestedObject: 'signupForm',
      mutation: 'UPDATE_STORE',
    }),
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      alert(JSON.stringify(this.form))
    },
    uploadPic (event) {
      const form = new FormData()
      form.set('file', event)
      axios.post('/public/v2/upload', form)
        .then(({ data }) => {
          this.agency_logo = data
        })
    },
  },

}
</script>

<style lang="scss" scoped>
.main-container {
  .agency_logo {
    display: none;
    cursor: pointer;
  }
  .agency_logo_upload {
    height: 200px;
    width: 200px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border-radius: 100%;
  }
  .main-row {
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(216, 216, 216, 0.5);
    border-radius: 12px;
    .form-control::-webkit-input-placeholder {
      font-weight: 300;
      font-size: 14px;
      font-style: italic;
      line-height: 120%;
      color: #979797;
      font-family: Jost;
    }
    .custom-file-label {
      color: red !important;
    }
  }
}
</style>
